/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        // Pre-loader
        $(window).load( function() { 
          $("#loading-status").delay(500).fadeOut(); 
          $("#pre-loader").delay(1000).fadeOut("slow"); 
        });
        // Animated Resizing Header On Scroll

        // Run FitVids on the main content area
        //$('.main').fitVids();
        // Fullscreen navigation

        // Smooth scroll
        $(".navbar-nav li a[href^='#']").on('click', function(e) {
          // prevent default anchor click behavior
          e.preventDefault();
          // store hash
          var hash = this.hash;

          // animate
          $('html, body').animate({
              scrollTop: $(this.hash).offset().top
            }, 1000, function(){

              // when done, add hash to url
              // (default click behaviour)
              window.location.hash = hash;
            });

        });
        // Close mobile nav on click
        $('.navbar-collapse ul li a').click( function() {
          $('.navbar-toggle:visible').click();
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired


      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        // Cache vars
        var $scroll = $('.scroll-action'),
            $arrow = $scroll.find('.fa-angle-down'),
            $action = $scroll.find('a'),
            $about = $("#section-about"),
            $contact = $("#section-contact"),
            $top = $("#scroll-top");

        // Run Skrollr
        //var s = skrollr.init();
        // Velocity animations
        // Jumbotron icon 
        $(".jumbotron .intro-heading").velocity("fadeIn", {
          opacity: 1,
          duration: 1000,
          delay: 2000
        });
        $arrow.velocity({
          translateY: "10px"
        }, {
          loop: true
        }).velocity("reverse");
        // Jumbotron scroll 
        $action.on("click", function(event) {
          event.preventDefault();
          $about.velocity("scroll", {
            duration: 1000,
            easing: "easeInBack"
          });
        });
        // Contact scroll 
        $("#action").find("a").on("click", function(event) {
          event.preventDefault();
          $contact.velocity("scroll", {
            duration: 1000,
            easing: "easeInBack"
          });
        });
        // Back to top
        $(window).scroll( function() {
          if ($(this).scrollTop() > 100) {
              $top.fadeIn();
          } else {
              $top.fadeOut();
          }
        });
        $top.on("click", function(event) {
          event.preventDefault();
          $("body").velocity("scroll", { 
            duration: 1000,
            easing: "easeInBack"
          });
        });
        // Toggle more (mobile only)
        $("#more-story").on("hide.bs.collapse", function() {
          $(".toggle-story>.btn").html('Read more');
        });
        $("#more-story").on("show.bs.collapse", function() {
          $(".toggle-story>.btn").html('Read less');
        });
        $("#more-project").on("hide.bs.collapse", function() {
          $(".toggle-project>.btn").html('Read more');
        });
        $("#more-project").on("show.bs.collapse", function() {
          $(".toggle-project>.btn").html('Read less');
        });
        // Slick carousel
        $('.testimonials .slider').slick({
          dots: true,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 18000,
          slidesToShow: 1,
          adaptiveHeight: true
        });
        // Isotope
        var $projects = $(".projects-list").isotope({
          itemSelector: ".project",
          layoutMode: 'fitRows',
          sortBy: 'random'
        });
        $projects.imagesLoaded().progress( function() {
          $projects.isotope('layout');
        });
        /* Filter on click */
        $('.filter-nav .nav .btn').click( function(e) {
          var $this = $(this),
              $filter = $this.attr('data-filter');

          e.preventDefault();

          $('.projects-list').isotope({
            filter: $filter
          });

          $('.filter-nav .nav .btn').removeClass('btn-active');
          $this.addClass('btn-active');

        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
       
        //console.log('home');


      }
    },
    // About us page, note the change from about-us to about_us.
    'contact_us': {
      init: function() {
        // JavaScript to be fired on the contact us page
        
        // gmaps.js
        /*new GMaps({
          div: '#map',
          lat: -12.043333,
          lng: -77.028333
        });*/
        
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
